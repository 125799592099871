<template>
  <button
    class="scroll-top-btn"
    @click="scrollToTop"
  >
    <svg
      width="58"
      height="59"
      viewBox="0 0 58 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon"
    >
      <path
        d="M58 29.2715C58 45.2877 45.0163 58.2715 29 58.2715C12.9837 58.2715 0 45.2877 0 29.2715C0 13.2552 12.9837 0.271484 29 0.271484C45.0163 0.271484 58 13.2552 58 29.2715Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29 56.3382C43.9485 56.3382 56.0667 44.22 56.0667 29.2715C56.0667 14.323 43.9485 2.20482 29 2.20482C14.0515 2.20482 1.93333 14.323 1.93333 29.2715C1.93333 44.22 14.0515 56.3382 29 56.3382ZM29 58.2715C45.0163 58.2715 58 45.2877 58 29.2715C58 13.2552 45.0163 0.271484 29 0.271484C12.9837 0.271484 0 13.2552 0 29.2715C0 45.2877 12.9837 58.2715 29 58.2715Z"
        fill="#18181B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.9511 14.77L29.9511 43.77H28.0232L28.0232 14.77L29.9511 14.77Z"
        fill="#18181B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.6784 25.4031C37.282 25.4031 35.8993 25.128 34.6092 24.5937C33.3191 24.0593 32.1469 23.276 31.1595 22.2886C30.1721 21.3012 29.3889 20.129 28.8545 18.8389C28.3201 17.5488 28.0451 16.1661 28.0451 14.7697L29.9784 14.7697C29.9784 15.9122 30.2034 17.0435 30.6407 18.0991C31.0779 19.1546 31.7187 20.1137 32.5266 20.9216C33.3345 21.7294 34.2935 22.3703 35.3491 22.8075C36.4046 23.2447 37.5359 23.4697 38.6784 23.4697V25.4031Z"
        fill="#18181B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.9536 14.7695C29.9536 16.1659 29.6786 17.5486 29.1442 18.8387C28.6099 20.1288 27.8266 21.301 26.8392 22.2884C25.8518 23.2758 24.6796 24.0591 23.3895 24.5935C22.0994 25.1278 20.7167 25.4029 19.3203 25.4029L19.3203 23.4695C20.4628 23.4695 21.5941 23.2445 22.6497 22.8073C23.7052 22.3701 24.6643 21.7292 25.4721 20.9214C26.28 20.1135 26.9208 19.1544 27.3581 18.0989C27.7953 17.0433 28.0203 15.912 28.0203 14.7695L29.9536 14.7695Z"
        fill="#18181B"
      />
    </svg>
  </button>
</template>

<script setup lang="ts">
const scrollToTop = () => {
  const top = document.getElementById('top')
  top?.scrollIntoView({ behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>
  .scroll-top-btn {
  position: fixed;
  right: 2rem;
  bottom: 1rem;
  z-index: 5;

  @media (max-width: 768px) {
    right: 1rem;
  }

  &:hover > .icon {
    color: var(--primary);
  }

  > .icon {
    cursor: pointer;
    color: var(--white);
    width: clamp(1.5rem, 0.3444rem + 4.9307vw, 3.5rem);
    height: clamp(1.5rem, 0.3444rem + 4.9307vw, 3.5rem);
  }
}
</style>
